<template>
  <router-view />
</template>

<script>
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import update from "@/mixins/update";
import sync from "@/mixins/sync";
import { mapGetters, mapActions } from "vuex";
import { TokenService } from "@services";
import { Trans } from "@/plugins/Translation";
import tracer from "@/diagnostics/tracer.js";

tracer.init();

export default {
  name: "App",

  metaInfo() {
    let robots;
    if (process.env.VUE_APP_META_ROBOTS) {
      robots = process.env.VUE_APP_META_ROBOTS;
    } else {
      robots = "index,follow";
    }
    return {
      titleTemplate: "%s | Ranes",
      meta: [{ name: "robots", content: robots }],
    };
  },

  mixins: [update, sync],

  computed: {
    ...mapGetters("settings", ["settings"]),
  },

  data() {
    return {
      pushes: null,
      pushReceived: null,
      info: "",
    };
  },

  methods: {
    ...mapActions("user", ["setUser"]),
    ...mapActions("auth", ["loadAccessToken"]),
    ...mapActions("notifications", ["getPushNotifications"]),
    ...mapActions("settings", ["setLang"]),

    async setStatusBar() {
      if (this.settings.darkTheme) {
        await StatusBar.setStyle({ style: Style.Dark }).catch(() => {});
      } else {
        await StatusBar.setStyle({ style: Style.Light }).catch(() => {});
      }
    },
  },
  mounted() {
    if (this.settings && this.settings.darkTheme) {
      this.$vuetify.theme.dark = true;
    } else if (this.settings.darkTheme === false) {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }
    this.setStatusBar();
    if (TokenService.getToken()) {
      this.loadAccessToken();
      this.setUser();
    }
  },
  created() {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      this.getPushNotifications();
    }
    if (this.settings.lang) {
      Trans.changeLanguage(this.settings.lang);
    }
  },
  watch: {
    "settings.darkTheme": function () {
      this.setStatusBar();
    },
  },
};
</script>
